<template>
    <router-view/>
</template>

<script>
  export default {
    name: 'index.vue',
    data () {
      return {}
    },
    created () {
      document.title = '营销活动'
      let path = this.$route.query.path
      let query = ''
      if (this.$route.query.query != 'undefined') {
        query = this.$route.query.query
      }
      if (!this.$utils.rule.empty(query)) {
        query = JSON.parse(decodeURIComponent(query))
      }
      if (path) {
        this.$router.replace({path: 'actives/' + path, query: query})
      }
    },
  }
</script>

<style scoped>

</style>